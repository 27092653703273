import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'
import ProTabs from '../../components/pro-tabs'
import {tabsContent } from "../../lib/pro-tabs-content"
// import {tabsContent, cardsLinks } from "../../lib/pro-tabs-content"

const AdvancedGateways = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner2@2x.jpg'),
    heading: "We're working to build Advanced Gateway Selection into Billsby",
    subheading: "So if you want to route Mastercard transactions to one gateway and American Express to another, have redundancy with fall back gateways if one goes down or split transaction between multiple providers, you can - and your customers won't even know it's happening."
  }

  return(
    <Layout>
      <SEO 
        title="Advanced Gateways | Billsby Pro | Take your subscription business to the next level" 
        description="Advanced Gateways and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/advanced-gateways"
      />

      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default AdvancedGateways;